import * as d3 from 'd3'

export default {

  data () {
    return {
      initd3: false,
      outerWidth: null,
      scrollWidth: null,
      baseTickCount: 15
    }
  },

  mounted () {
    this.attachResizeListener()
    this.delayedD3Draw()
  },

  beforeDestroy () {
    this.dettachResizeListener()
  },

  computed: {
    skipDraw () {
      return false
    },
    tickCount () {
      return (this.width / this.outerWidth) * this.baseTickCount
    }
  },

  methods: {

    attachResizeListener () {
      window.addEventListener('resize', this.onWindowResize)
    },

    dettachResizeListener () {
      window.removeEventListener('resize', this.onWindowResize)
    },

    onWindowResize () {
      this.initWidths()
      this.setBaseTickCount()
    },

    initWidths () {
      if (this.$refs.ticksouter !== undefined) {
        this.outerWidth = this.$refs.ticksouter.clientWidth
        this.scrollWidth = this.$refs.ticksouter.scrollWidth
      }
    },
    setBaseTickCount() {
      this.baseTickCount = this.outerWidth / 100
    },
    delayedD3Draw () {
      this.$nextTick(() => {
        this.initd3 = true
        this.$nextTick(() => {
          this.draw()
        })
      })
    },

    clear () {
      d3.select('#ticksvg svg').remove()
    },

    draw () {
      if (this.outerWidth === null) this.onWindowResize()
      if (this.skipDraw === true) return

      this.clear()

      var svg = d3.select('#ticksvg')
        .append('svg')
        .attr('width', this.width)
        .attr('height', this.outerHeight + 'px')

      var x = d3.scaleLinear()
        .domain([0, this.overallduration])
        .range([0, this.width])

      let axis = d3.axisTop(x)
        .ticks(this.tickCount)
        .tickFormat(formatCustom)
        .tickSize(3)

      let translateY = this.outerHeight - 0
      svg
        .append('g')
        .attr('transform', 'translate(0,' + translateY + ')') // This controls the vertical position of the Axis
        .attr('stroke', 'gray')
        .call(axis)

      this.drawnScale = this.width

      function formatCustom (value) {
        let m = Math.floor(value / 60)
        let s = (value % 60)

        return zeroPad(m, 2) + ':' + zeroPad(s, 2)
      }

      function zeroPad (number, places) {
        let floored = Math.floor(number)
        let zero = places - floored.toString().length + 1
        return Array(+(zero > 0 && zero)).join('0') + number
      }
    }
  }
}
